<template>
	<div class="convention-hall page" style="height: 100%;">
		<van-nav-bar class="nav-bar" :title="$t('my.account_detail')" >
		<template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
	</van-nav-bar>
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('my.recharge')">
					
						<van-empty v-if="list_r.length === 0" :description="$t('withdraw.empty_data')" />
							<div v-else class="item_list" v-for="(v,key) in list_r" :key="key"  >
								<div class="topInfo">
								<span v-if="v.status === 2" style="color: #07c160">{{v.status_text}}</span>
								<span v-else-if="v.status === 4" style="color: #07c160">{{v.status_text}}</span>
								<span v-else >{{v.status_text}}</span>
								<span style="color: #07c160">{{$t("withdraw.money")}}：+{{v.money}}</span>
								</div>
								<!-- <div class="desc">
								<span>{{$t("withdraw.desc")}}：{{v.desc}}</span>
								</div> -->
								<div class="time">
								<span>{{$t("withdraw.submit_time")}}：{{v.create_time}}</span>
								</div>
								<div class="time">
								<span>{{$t("withdraw.check_time")}}：{{v.update_time}}</span>
								</div>
								
							</div>
							
					
				</van-tab>
				<van-tab :title="$t('my.withdraw')">
					<van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
							<div v-else class="item_list" v-for="(vv,key1) in list" :key="key1">
								<div class="topInfo">
								<span v-if="vv.status === 2" style="color: #07c160">{{vv.status_text}}</span>
								<span v-else-if="vv.status === 4" style="color: #07c160">{{vv.status_text}}</span>
								<span v-else >{{vv.status_text}}</span>
								<span  >{{$t("withdraw.money")}}：-{{vv.money}}</span>
								</div>
								<!-- <div class="desc">
								<span>{{$t("withdraw.desc")}}：{{vv.desc}}</span>
								</div> -->
								<div class="time">
								<span>{{$t("withdraw.submit_time")}}：{{vv.create_time}}</span>
								</div>
								<div class="time">
								<span>{{$t("withdraw.check_time")}}：{{vv.update_time}}</span>
								</div>
							</div>
				</van-tab>
				<van-tab :title="$t('reservation.task_list')">
					<van-empty v-if="list_g.length === 0" :description="$t('withdraw.empty_data')" />
							<div v-else class="item_list" v-for="(vvv,key2) in list_g" :key="key2">
								<div class="topInfo">
								<!-- <span v-if="vvv.status === 2" style="color: #07c160">{{vvv.status_text}}</span>
								<span v-else-if="vvv.status === 4" style="color: #07c160">{{vvv.status_text}}</span> -->
								<span  >{{vvv.expect}}</span>
								<span  v-if="vvv.is_win==1"  style="color: #07c160">+{{vvv.money}}</span>
								<span  v-if="vvv.is_win==2" >-{{vvv.money}}</span>
								</div>
								<!-- <div class="desc">
								<span>{{$t("withdraw.desc")}}：{{vv.desc}}</span>
								</div> -->
								<div class="time">
								<span>{{$t("withdraw.submit_time")}}：{{vvv.create_time}}</span>
								</div>
								<div class="time">
								<span>{{$t("withdraw.check_time")}}：{{vvv.update_time}}</span>
								</div>
							</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [],
			list:[],
			list_r:[],
			list_g:[]
		};
	},
	methods: {
		back(){
		return window.history.back();
		},
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
				console.log(res.data)
			});
		},
		getUserWithdrawList(){
		this.$http({
			method: 'get',
			url: 'user_get_withdraw_list'
		}).then(res=>{
			if(res.code === 200){
			this.list = res.data;
			}else if(res.code ===401){
			// this.$toast(res.msg);
			}
		})
		},
		getUserRechargeList(){
		this.$http({
			method: 'get',
			url: 'user_get_recharge_list'
		}).then(res=>{
			if(res.code === 200){
			this.list_r = res.data;
			}else if(res.code ===401){
			// this.$toast(res.msg);
			}
		})
		},
		getUserGameList(){
		this.$http({
			method: 'get',
			url: 'user_get_game_list'
		}).then(res=>{
			if(res.code === 200){
			this.list_g = res.data;
			console.log(res.data)
			}else if(res.code ===401){
			// this.$toast(res.msg);
			}
		})
		}

	},
	created() {
		// this.getAddress();
		if(!localStorage.getItem('token')){
		this.$router.push({path:'/Login'})
		}else {
		this.getUserWithdrawList();
		this.getUserRechargeList();
		this.getUserGameList();
		}
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #1b1b1b;
}
.nav-bar {
	background-color: #1b1b1b;
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #ffffff;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.van-sidebar {
	width: 180px;
}
// /deep/ .van-col{
	// padding: 30px 0px;
// }
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background-color: #1b1b1b;
	height: max-content;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #f487e0;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}

/deep/ .van-cell{
	padding: 30px 22px;
	font-size: 30px;
	line-height:30px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
	margin-bottom: 110px;
}
::v-deep .van-tabs__nav {
	background-color: #1b1b1b;
	}
	::v-deep .van-tab--active {
		color: #fc9;
	}
</style>
