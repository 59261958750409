<template>
	<div class="convention-hall page">
		<van-nav-bar :title="$t('choose.title')" class="nav-bar">
			<!-- <template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template> -->
		</van-nav-bar>
		<div class="right">
			<div class="selects">
				<div class="select" @click="showSelectArea()">
					<span class="">{{ addVal }}</span>
					<van-icon name="arrow-down" class="select-icon" />
				</div>
				<div class="select" style="margin-right: 2vw;" @click="showSelectAge()">
					<span class="">{{ ageVal }}</span>
					<van-icon name="arrow-down" class="select-icon" />
				</div>
			</div>
			<van-pull-refresh 
			pulling-text="새로고침 성공" 
			loosing-text="페이지 새로 고침" 
			loading-text="새로고침 성공" 
			success-text="새로고침 성공" class="list-wrapper" v-model="isLoading" @refresh="onRefresh" style="margin-bottom: 100px;">
			<van-list
                    v-model="loading"
                    :finished="finished"
					:immediate-check="false"
                    finished-text="그 이상 아닌"
					loading-text="새로고침 성공" 
                    @load="onLoad"
            >
				
				<van-grid :column-num="2" :gutter="10">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<van-image class="game_item_img" :src="v.avatar">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
						<span class="rig-name">{{ v.yn_yu }}</span>
						<span class="rig-txt">{{ v.age }}/{{ v.size }}/{{ v.weight }}KG</span>
						<van-button round="true" @click="yuyue" class="rig-button button" >{{$t("othe.choose_but")}}</van-button>
					</van-grid-item>
				</van-grid>
			</van-list>
			</van-pull-refresh>
		</div>
<!-- 区域选择 -->
		<van-popup v-model="showArea" round position="bottom" :style="{ height: '35%' }" >
			<van-picker
				show-toolbar
				:columns="addlist"
				@confirm="onConfirm"
				@cancel="onCancel"
				:confirm-button-text="$t('setting.ok')"
				:cancel-button-text="$t('setting.cancel')"
			>
				<template #option="option">
				<div style="display: flex; flex-direction: column; align-items: center;">
					<div>{{ option.name }}</div>
				</div>
				</template>
			</van-picker>
    	</van-popup>
<!-- 年龄选择 -->
		<van-popup v-model="showAge" round position="bottom" :style="{ height: '35%' }" >
			<van-picker
				show-toolbar
				:columns="agelist"
				@confirm="onConfirm02"
				@cancel="onCancel02"
				:confirm-button-text="$t('setting.ok')"
				:cancel-button-text="$t('setting.cancel')"
			>
			</van-picker>
    	</van-popup>
		
	</div>
</template>

<script>
import { Toast } from 'vant';
export default {
	data() {
		return {
			vod_name: '111',
			isLoading: false,
			loading: false,
      		finished: false,
			refreshing: false,
			datalist: [
			],
			addlist: [],
			showArea: false,
			showAge: false,
			agelist: [
				{
					text:"20-25",
					value:"20,25"
			
				},
				{
					text:"25-30",
					value:"25,30"
			
				},
				{
					text:"30-35",
					value:"30,35"
			
				},
				{
					text:"35-40",
					value:"35,40"
			
				}
			],
			chooseId: 200,
			minAge: null,
			maxAge: null,
			ageVal: "연령",
			addVal: "지역",
			page:1,
			count:0,
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Choose' });
		},
		//下拉刷新
		onRefresh() {
			setTimeout(() => {
				this.finished = false;
				this.loading = true;
				this.onLoad();
				this.isLoading = false;
				Toast(this.$t("reservation.refresh"));
      		}, 500);
		},
		//上滑动
		onLoad() {
			this.getChooseList();

			let timer = setTimeout(() => {
				this.loading = false;
				if (this.refreshing) {
				this.datalist = [];
				this.refreshing = false;
				}
				
				if (this.datalist.length >= this.count) {
				this.finished = true;
				}
				this.finished && clearTimeout(timer);//清除计时器
			}, 500);
    	},
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: 200,page:this.page }
			}).then(res => {
				this.datalist = res.data;
				this.count = res.data.count;
        		this.page++;
			});
		},
		showSelectArea(){
			
			this.getAddress()
      		this.showArea = true;
    	},
		showSelectAge(){
      		this.showAge = true;
			  
    	},
		onConfirm(value) {
			// 清空分页数据和旧数据
			this.page = 1;
			this.count = 0;
			this.datalist = []

			this.chooseId = value.id
			this.addVal = value.name
			this.showArea = false;
			this.getChooseList()
		},
    	onCancel() {
      		this.showArea = false;
    	},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
				
			});
		},
		onConfirm02(value) {
			// 清空分页数据和旧数据
			this.page = 1;
			this.count = 0;
			this.datalist = []

			this.showAge = false;
			this.ageVal = value.text
			var ages = value.value.split(',');
			this.minAge = ages[0]
			this.maxAge = ages[1]
			this.getChooseList()
		},
    	onCancel02() {
      		this.showAge = false;
    	},
		getChooseList() {
			// this.datalist=null;
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: this.chooseId,min_age: this.minAge,max_age: this.maxAge,page:this.page }
			}).then(res => {
				this.datalist = this.datalist.concat(res.data.data);
				this.count = res.data.count;
        		this.page++;
				
			});
		},
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getChooseList();
	},
	beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#1b1b1b;')
     },

};
</script>

<style scoped>
.right {
	margin-top: 10px;
}
::v-deep .van-pull-refresh {
	/* height: 220vw; */
}
::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
	border: 0.5vw solid #fc9;
}
::v-deep .van-image__img {
	border-radius: 10px;
	/* padding: 15px; */
}
.rig-name {
	position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12vw;
    height: 8vw;
    background: #ff2a77;
    border-radius: 4vw 0px 4vw 0px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 4vw;
    color: #fff;
    line-height: 6vw;
}
.rig-txt{
	position: absolute;
    bottom: 13vw;
    left: 50%;
    /* -webkit-transform: translate(-50%); */
    transform: translate(-50%);
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 4vw;
    color: #ffffff;
    line-height: 3vw;
}
.rig-button{
	position: absolute;
    bottom: 3vw;
    left: 50%;
    transform: translate(-50%);
    display: flex
;
    align-items: center;
    justify-content: center;
    width: 30vw;
    height: 8vw;
    background: linear-gradient(225deg, #fa4f7e, #e92357);
    box-shadow: 0px 0px 0.8vw 0px rgba(248, 74, 122, .5);
    border-radius: 5vw;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    /* font-size: 4vw; */
    color: #ffffff;
    line-height: 2.533vw;
}
.selects{
	width: 100%;
    height: 10vw;
    justify-content: space-between;
    margin-top: 5vw;
    margin-bottom: 10vw;
	display: flex;
}
.select{
	padding: 2vw 2vw;
    box-sizing: border-box;
    border-radius: 2vw;
    font-weight: 500;
    font-size: 5vw;
    color: #fff;
    border: 1px solid #fc9;
    /* width: 40vw; */
    margin-left: 2vw;
}
.select-icon{
	margin-left: 5vw;
    color: #fc9;
}
::v-deep .van-picker-column {
	font-size: 4vw;
}
::v-deep .van-picker__cancel  {
	font-size: 4vw;
}
::v-deep .van-picker__confirm  {
	font-size: 4vw;
}
</style>
