<template>
	<div class="page">
		<van-nav-bar :title="$t('concubine.concubine')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div style="display: flex; align-items: center; justify-content: space-evenly;padding: 12px 20px 0px;">
			<div class="box-info">
				<p class="vle">{{ this.xuanfeidata.age }}</p>
				<p class="title">연령</p>
			</div>
			<div class="box-info">
				<p class="vle">{{ this.xuanfeidata.weight }}</p>
				<p class="title">체중/KG</p>
			</div>
			<div class="box-info">
				<p class="vle">{{ this.xuanfeidata.size }}</p>
				<p class="title">컵 사이즈</p>
			</div>
			<!-- <div class="box-info">
				<p class="vle">{{ this.xuanfeidata.age }}</p>
				<p class="title">간략한 소개</p>
			</div> -->
		</div>
		<div style="display: flex; align-items: center; justify-content: space-between;padding: 12px 20px 0px;">
			<div class="box-info" style="text-align: left;">
				<p class="title">지역 : </p>
				<p class="vle" style="padding-left: 50px;">{{ this.xuanfeidata.className }}</p>
			</div>
		</div>

		<div style="display: flex; align-items: center; justify-content: space-between;padding: 12px 20px 0px;">
			<div class="box-info" style="text-align: left;">
				<p class="title">간략한 소개: </p>
				<p class="vle" style="padding-left: 100px;">{{ this.xuanfeidata.desc }}</p>
				
			</div>
		</div>
		<div class="box">
			<van-image style="margin-bottom: 17px;width: 98%;height: 100%;" fit="contain"  v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<!-- <van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">{{$t("foorter.subscribe")}}</van-button> -->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: []
		};
	},
	methods: {
		back() {
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
			this.$router.push({ path: 'newChoose'});
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
				console.log("xuanfeidata: ",this.xuanfeidata)
			});
		},
		yuyue() {
			this.$toast(this.$t("reservation.counselor"));
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}
.vle {
	font-size: 1rem;
    color: rgb(239, 214, 166);
}
.title {
	font-size: 1rem;
    color: rgb(204, 204, 204);
    font-weight: 400;
	height: 0px;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
.box-info{
	text-align: center;
}
::v-deep .van-image__img {
	border-radius: 11px;
}

</style>
