<template>
	<div class="container page">
		<van-nav-bar :title="this.vod_name" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="right">
			<van-pull-refresh 
			pulling-text="새로고침 성공" 
			loosing-text="페이지 새로 고침" 
			loading-text="새로고침 성공" 
			success-text="새로고침 성공" border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
				<van-grid :column-num="2" :gutter="10">
					<van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
						<van-image class="game_item_img" :src="v.avatar">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>
						<span class="rig-name">{{ v.yn_yu }}</span>
						<span class="rig-txt">{{ v.age }}/{{ v.size }}/{{ v.weight }}KG</span>
						<van-button round="true" @click="yuyue" class="rig-button button" >{{$t("othe.choose_but")}}</van-button>
					</van-grid-item>
				</van-grid>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			vod_name: '北京',
			isLoading: false,
			datalist: [
				// {
				// 	xuanfei_name: '北京 健身达人',
				// 	img_url: 'https://kk.betman2.co/storage/41/62fe1bd26d01c_image_2022-08-18_190032731.png'
				// },
				// {
				// 	xuanfei_name: '北京 学生妹',
				// 	img_url: 'https://kk.betman2.co/storage/344/630869b4bd2d6_1.jpg'
				// }
			]
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'Choose' });
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("reservation.refresh"));
				this.isLoading = false;
			}, 500);
		},
		profile(id) {
			this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
		},
		getxuanfeilist() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.datalist = res.data;
				console.log("datalist: ",this.datalist)
			});
		}
	},
	created() {
		this.vod_name = this.$route.query.name;
		this.getxuanfeilist();
	},
	beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#1b1b1b;')
     },

};
</script>

<style scoped>
.right {
	margin-top: 10px;
}
::v-deep .van-pull-refresh {
	/* height: 220vw; */
}
::v-deep .van-grid-item__content--center {
	border-radius: 15px;
	padding: 0;
	height: auto;
	border: 0.5vw solid #fc9;
}
::v-deep .van-image__img {
	border-radius: 10px;
	/* padding: 15px; */
}
.rig-name {
	position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12vw;
    height: 8vw;
    background: #ff2a77;
    border-radius: 4vw 0px 4vw 0px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 4vw;
    color: #fff;
    line-height: 6vw;
}
.rig-txt{
	position: absolute;
    bottom: 13vw;
    left: 50%;
    /* -webkit-transform: translate(-50%); */
    transform: translate(-50%);
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 4vw;
    color: #ffffff;
    line-height: 3vw;
}
.rig-button{
	position: absolute;
    bottom: 3vw;
    left: 50%;
    transform: translate(-50%);
    display: flex
;
    align-items: center;
    justify-content: center;
    width: 30vw;
    height: 8vw;
    background: linear-gradient(225deg, #fa4f7e, #e92357);
    box-shadow: 0px 0px 0.8vw 0px rgba(248, 74, 122, .5);
    border-radius: 5vw;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    /* font-size: 4vw; */
    color: #ffffff;
    line-height: 2.533vw;
}
</style>
